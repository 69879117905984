import React from 'react';
import MainLayout from '../../layouts/main';
import { Typography } from '@material-ui/core';
import Section, { Label } from '../../components/Section';
import { SEO } from '../../components/SEO';
import { SignupButtonWithChoice } from '../../components/SignupButton';
import PageHead from '../../components/PageHead';
import {
  DashboardAttribution,
  DashboardExport,
  DashboardFilter,
  DashboardMetadata,
  DashboardMetrics,
  DashboardMultipleConnections,
  DashboardReports,
  DashboardTeams,
  DashboardTimeline,
  FeatureGrid
} from '../../components/FeatureGrid';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/affiliate-dashboard/"
          title="The #1 Affiliate Dashboard for Content Websites"
          description="Affilimate helps media brands understand the true ROI of their content, figure out which products resonate with their audience, and grow their affiliate revenue in a systematic way."
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '750px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Affiliate dashboards and APIs tailored exclusively for content
            publishers
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '510px', margin: '24px auto 12px' }}
          >
            Aggregate affiliate commissions from 100+ platforms in a single
            dashboard, and enrich your conversions with missing attribution data
            that helps you grow your revenue.
          </Typography>
          <img
            src="/images/features/dashboard-integrations-top.png"
            style={{
              width: '80%',
              maxWidth: '700px',
              margin: '0 auto',
              display: 'block'
            }}
            alt="Affiliate dashboard"
          />
          <div style={{ textAlign: 'center', margin: '48px auto 0' }}>
            <SignupButtonWithChoice text="Get started" />
          </div>
        </PageHead>
        <Section
          image="/images/features/affiliate-dashboard/integration-setup.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Integrations</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Integrate with 100+ affiliate networks, platforms, and programs
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Monitor your commissions and run cross-cutting reports on
                affiliate earnings from over one hundred different platforms.
              </Typography>

              <Typography paragraph variant="body1">
                All major networks, aggregators, and SaaS software solutions
                included.
              </Typography>

              <Typography paragraph variant="body1">
                Set up in minutes without a developer.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/affiliate-dashboard/dashboard-view.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Unified dashboard</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                All your affiliate commissions in one place
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Transaction reports show you your latest conversions, and which
                page and link the conversion happened on.
              </Typography>
              <Typography paragraph variant="body1">
                Stop asking yourself what's working start crafting a
                data-informed affiliate strategy.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/affiliate-dashboard/transaction-details.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>SKU Reporting</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Click any transaction to reveal product details and metadata
              </Typography>
            </>
          }
          body={
            <Typography paragraph variant="body1">
              Uncover the data points buried inside your affiliate reports that
              tell you which products your audience actually wants to buy.
            </Typography>
          }
        />
        <div style={{ textAlign: 'center', margin: '48px auto 96px' }}>
          <SignupButtonWithChoice text="Get started" />
        </div>
        <div style={{ maxWidth: '900px', margin: '96px auto 48px' }}>
          <Typography
            variant="h4"
            gutterBottom
            component="h2"
            style={{
              textAlign: 'center',
              fontWeight: 900
            }}
          >
            But wait, there's more
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{
              maxWidth: '510px',
              margin: '0 auto',
              textAlign: 'center'
            }}
          >
            Combine a simple interface with powerful features like these
          </Typography>
        </div>
        <FeatureGrid>
          <DashboardMetrics />
          <DashboardReports />
          <DashboardMultipleConnections />
          <DashboardMetadata />
          <DashboardFilter />
          <DashboardExport />
          <DashboardTimeline />
          <DashboardAttribution />
          <DashboardTeams />
        </FeatureGrid>
        <div style={{ textAlign: 'center', margin: '48px auto 96px' }}>
          <img
            src="/images/features/dashboard-integrations-top.png"
            style={{
              width: '80%',
              maxWidth: '450px',
              margin: '0 auto 24px',
              display: 'block'
            }}
            alt="Affiliate dashboard"
          />
          <SignupButtonWithChoice text="Get started" />
        </div>
      </div>
    </MainLayout>
  );
};
